//wallet
export const CONNECT_WALLET = 'CONNECT_WALLET';

// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// HOME
export const HOME_PAGE_DATA = 'HOME_PAGE_DATA';
export const BEFORE_HOME = 'BEFORE_HOME';

// AUCTIONS
export const BEFORE_AUCTION = 'BEFORE_AUCTION';
export const GET_AUCTIONS = 'GET_AUCTIONS';

// NFTS
export const BEFORE_NFT = 'BEFORE_NFT';
export const CREATE_NFT_AUTH = ' CREATE_NFT_AUTH';
export const GET_NFTS = 'GET_NFTS';
export const GET_NFT = 'GET_NFT';
export const UPSERT_NFT = 'UPSERT_NFT';
export const GET_NFTS_BY_COLLECTION = '';
export const GET_MYLIKED_NFTS = ' GET_MYLIKED_NFTS';
export const GET_PROFILE_NFTS = 'GET_PROFILE_NFTS';
export const TOGGLE_LIKE_RES = 'TOGGLE_LIKE_RES';
export const BEFORE_TOGGLE_LIKE = 'BEFORE_TOGGLE_LIKE';
export const BUY_NOW = 'BUY_NOW';
export const BEFORE_NFTS_BY_COLLECTION = 'BEFORE_NFTS_BY_COLLECTION';

//ITEM ACTIVITY
export const CREATE_ACITIVITY = 'CREATE_ACITIVITY';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';

//ITEM LISTING
export const GET_ITEM_LISTING = 'GET_ITEM_LISTING';

//CREATE NFT
export const SET_NFT_TOKEN = 'SET_NFT_TOKEN';
export const CREATE_NFT = 'CREATE_NFT';

// WALLET
export const BEFORE_WALLET = 'BEFORE_WALLET';
export const SET_WALLET = 'SET_WALLET';
export const GET_WALLET = 'GET_WALLET';
export const GET_BLOCKCHAIN_TYPE = 'GET_BLOCKCHAIN_TYPE';
export const SET_BLOCKCHAIN_TYPE = 'SET_BLOCKCHAIN_TYPE';

// USERS
export const BEFORE_USER = 'BEFORE_USER';
export const SET_USER = 'SET_USER';
export const GET_USER = 'GET_USER';
export const SET_CREATORS = 'SET_CREATORS';
export const TOP_SELLERS = 'TOP_SELLERS';
export const SET_INDIVIDUAL_USER = 'SET_INDIVIDUAL_USER';
export const SET_AUTH_STATE = 'SET_AUTH_STATE';
export const UPDATE_API_STATE = 'UPDATE_API_STATE';

// CATEGORIES
export const BEFORE_CATEGORY = 'BEFORE_CATEGORY';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const COLLECTION_CATEGORES = 'COLLECTION_CATEGORE';

// COLLECTION
export const BEFORE_COLLECTION = 'BEFORE_COLLECTION';
export const GET_COLLECTION = 'GET_COLLECTION';
export const UPSERT_COLLECTION = 'UPSERT_COLLECTION';
export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const GET_COLLECTIONS = 'GET_COLLECTIONS';
export const ALL_COLLECTIONS = 'ALL_COLLECTIONS';
export const MY_COLLECTIONS = 'MY_COLLECTIONS';
export const GET_POPULAR_COLLECTIONS = 'GET_POPULAR_COLLECTIONS';
export const BEFORE_POPULAR_COLLECTION = 'BEFORE_POPULAR_COLLECTION';
export const COLLECTION_DEPLOY_ABI = 'COLLECTION_DEPLOY_ABI';
// FOOTER
export const BEFORE_FOOTER = 'BEFORE_FOOTER';
export const GET_FOOTER = 'GET_FOOTER';
export const BEFORE_SUBSCRIBE = 'BEFORE_SUBSCRIBE';
export const SUBSCRIBE = 'SUBSCRIBE';
export const SUBSCRIBE_RESPONSE = 'SUBSCRIBE_RESPONSE';
// FAQ
export const BEFORE_FAQ = 'BEFORE_FAQ';
export const GET_FAQS = 'GET_FAQS';

// OFFER
export const BEFORE_OFFER = 'BEFORE_OFFER';
export const GET_OFFERS = 'GET_OFFERS';
export const DELETE_OFFER = 'DELETE_OFFER';
export const CREATE_OFFER = 'CREATE_OFFER';
export const ACCEPT_OFFER = 'ACCEPT_OFFER';

// SYSTEM SETTING

export const GET_SYSTEM_SETTINGS = 'GET_SYSTEM_SETTINGS';
export const GET_SYSTEM_FEE = 'GET_SYSTEM_FEE';

// BID
export const BEFORE_BID = 'BEFORE_BID';
export const GET_BIDS = 'GET_BIDS';
export const DELETE_BID = 'DELETE_BID';
export const CREATE_BID = 'CREATE_BID';
export const ACCEPT_BID = 'ACCEPT_BID';
export const GETBIDS_BY_NFT = 'GETBIDS_BY_NFT';

// CONTACT
export const BEFORE_CONTACT = 'BEFORE_CONTACT';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const NFTALL = 'NFTALL';

//Approvals
export const GET_APPROVALS = 'GET_APPROVALS';
export const EDIT_APPROVAL = 'EDIT_APPROVAL';
