/* eslint-disable no-prototype-builtins */
import { useWallet } from '@solana/wallet-adapter-react';
import { useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import darkModeContext from '../../context/darkmode/DarkModeContext';
import { ENV } from '../../config/config';
export const UseWalletChange = (connectedAddress: any) => {
	const wallet = useWallet();
	const history = useHistory();
	const location = useLocation();
	const { darkMode, setDarkMode } = useContext(darkModeContext);
	const currentPath = window.location.pathname;
	useEffect(() => {
		if (
			wallet.connected === false &&
			ENV.getUserKeys('blockchainType')?.blockchainType === 'solana'
		) {
			const itemToKeep = 'walletName';
			for (const key in localStorage) {
				if (localStorage.hasOwnProperty(key) && key !== itemToKeep) {
					localStorage.removeItem(key);
				}
			}
			const walletsName = localStorage.getItem('walletName');
			toast.info(
				`Your ${
					walletsName ? walletsName : 'Solane'
				} wallet is disconnected please login again`,
				{
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: 'light',
					closeButton: true,
				}
			);
			return history.push('/connect-wallet');
		}
	}, []);

	useEffect(() => {
		if (
			wallet.publicKey?.toBase58() &&
			connectedAddress &&
			connectedAddress !== wallet.publicKey?.toBase58() &&
			location.pathname !== '/connect-wallet' &&
			ENV?.getUserKeys('blockchainType')?.blockchainType === 'solana'
		) {
			// toast.info('Please login with new connected wallet');
			// localStorage.clear();
			const itemToKeep = 'walletName';
			for (const key in localStorage) {
				if (localStorage.hasOwnProperty(key) && key !== itemToKeep) {
					localStorage.removeItem(key);
				}
			}
			localStorage.setItem('theme', darkMode ? 'dark' : 'light');
			return history.push('/connect-wallet');
		}
	}, [wallet.publicKey]);

	useEffect(() => {
		// const interval = setInterval(() => {
		// 	if (
		// 		!wallet.connected &&
		// 		ENV.getUserKeys('blockchainType')?.blockchainType === 'solana'
		// 	) {
		// 		// localStorage.clear();
		// 		// localStorage.setItem('theme', darkMode ? 'dark' : 'light');
		// 		// return history.push('/connect-wallet');
		// 	}
		// }, 3000);
		// return () => clearInterval(interval);
	}, []);
	return null;
};
