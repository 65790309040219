// Home
export const HOME_TEST = 'HOME_TEST';

//FourStepForm
export const CURRENT_STEP = 'CURRENT_STEP';
export const CURRENT_STEP_DECREMENT = ' CURRENT_STEP_DECREMENT';
export const CLEAR_CURRENT_STEP = 'CLEAR_CURRENT_STEP';

//DeployContract

export const DEPLOY_CONTRACT = 'DEPLOY_CONTRACT';
export const SET_CONTRACT_INFO = 'SET_CONTRACT_INFO';
export const CONNECT_WELLET = 'CONNECCT_WALLET';
export const CONTRACT_ERROR_MESSAGE = 'CONTRACT_ERROR_MESSAGE';
export const CONTRACT_REPONSE = 'CONTRACT_REPONSE';
export const TOKEN_ID = 'TOKEN_ID';
export const MINTING_RESPONSE = 'MINTING_RESPONSE';
export const LOADER = 'LOADER';
export const CONTRACT_ADDRESS = 'CONTRACT_ADDRESS';

//Step 3 form
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_LEVELS = 'SET_LEVELS';

export const SET_OTP_EMAIL = 'SET_OTP_EMAIL';

//otp verificaiton
export const SET_OTP_STATUS = 'SET_OTP_STATUS';

// all pagination
export const SET_COLLECTION_PAGINATION = 'SET_COLLECTION_PAGINATION';
export const SET_COLLECTION_LIMIT = 'SET_COLLECTION_LIMIT';
export const SET_NFT_PAGINATION = 'SET_NFT_PAGINATION';
export const SET_NFT_LIMIT = 'SET_NFT_LIMIT';
